import _ from 'underscore'

import { PRODUCT_ND } from '../constants/cipher_product_ids.js'
import { HAS_PATENTSIGHT_SYNC_CLASSIFIERS, PATENTSIGHT_ATTRIBUTES } from '../constants/patentsight_export_attributes.js'
import { ACCOUNT_TYPE_ROLES } from './user_management_utils.js'
import { is_tech_discovery } from './utils.js'

const HAS_CLASSIFIERS_EDIT = 'has_classifiers_edit'
const HAS_TEXT_TO_NEAREST = 'has_text_to_nearest'
const HAS_ND_REPORT = 'has_nd_report'
const HAS_LOTNET_EXPORT = 'has_lotnet_export'
const HAS_REASSIGNMENT_EXPORT = 'has_reassignment_export'
const HAS_REPORT_SERIES_SORT = 'has_report_series_sort'
const HAS_RESTRICTED_FAMILY_FIELDS = 'has_restricted_family_fields'
const HAS_TRENDSPOTTER = 'has_trendspotter'
const HAS_SET_THEORY_SERVICE = 'has_set_theory_service'
const HAS_BENCHMARKING_BUILDER = 'has_benchmarking_builder'
const CAN_TAG_FAMILIES = 'has_tag_families'
const HAS_VIEW_TAGGED_FAMILIES = 'has_view_tagged_families'
const HAS_HS_GOOGLE_VALUATION = 'has_hs_google_valuation'
const HAS_QUICK_ORG_SEARCH = 'has_quick_org_search'
const HAS_US_CENTRIC_TERRITORIES = 'has_us_centric_territories'
const HAS_OLD_CLASSIFIER_ALERTS = 'has_old_classifier_alerts'
const HAS_TRACKING_DISABLED = 'has_tracking_disabled'

const HAS_PVIX_DISABLED = 'has_pvix_disabled'

const IS_ACCOUNT_DISABLED = 'is_account_disabled'
const IS_ADMIN = 'is_admin'
const IS_AISTEMOS = 'is_aistemos'
const IS_AISTEMOS_DEMO = 'is_aistemos_demo'
const IS_CLASSIFIERS_ADMIN = 'is_classifiers_admin'
const IS_LOTNET_MEMBER = 'is_lotnet_member'
const IS_SSO_USER = 'is_sso_user'
const HAS_VIEW_ADMIN_PAGE = 'has_view_admin_page'
const HAS_VIEW_CREATE_USERS_PAGE = 'has_view_create_users_page'
const IS_CIPHER_ENGINEERING = 'is_cipher_engineering'
const IS_POWERUSER = 'is_poweruser'
const IS_TECH_INSIGHTS = 'is_tech_insights'
const IS_LOTNET = 'is_lot_net'
const IS_GOOGLE = 'is_google'

export const IDENTITY_PROVIDER          = 'identity_provider'
export const IDENTITY_PROVIDER_IDENTITY = 'identity_provider_identity'
export const IS_CIPHER_WAM_USER         = 'is_cipher_wam_user'

export const DEFAULT_CLASSIFIER_ENGINE = 'default_classifier_engine'

const HAS_ASSIGNEE_GROUPING = 'has_assignee_grouping'

const IS_VIEW_ONLY = 'is_view_only'  // user can not create reports at all
export const IS_LITE = 'is_lite'
export const IS_SUBSCRIBER = 'is_subscriber'
export const IS_TRIAL = 'is_trial'

const REPORT_SERIES_SORT = 'report_series_sort'
const RESTRICTED_LIST_VIEW_COLUMNS = 'restricted_list_view_columns'

const PAT_FAM_ID = 'patFamId'
const CIPHER_FAMILY_ID = 'cipherFamilyId'
const ABSTRACT_TEXT = 'abstractText'
const ABSTRACT = 'abstract'

const AISTEMOS_GROUP      = '/Aistemos'
const LNIP_GROUP          = '/LNIP'
const LNIP_DEMO_1_GROUP   = '/Lexis Nexis 1 (DEMO)'
const TECH_INSIGHTS_GROUP = '/TechInsights'
const LOTNET_GROUP        = '/LotNet'
const GOOGLE_GROUP        = '/Google'
const AISTEMOS_DEMO_GROUP = '/Aistemos Demo'

const WAM_PROD_IDP_ID = 'wam-pdc1c'


export function get_user_object(kc, tech_discovery__wam_details) {
  const token = kc.tokenParsed

  if (tech_discovery__wam_details) {
    // TechDiscovery user object
    const { idTokenParsed } = kc
    const { email, sub } = idTokenParsed

    const { org_id, org_name, user_name } = tech_discovery__wam_details
    const group       = ['/' + org_name]
    const group_ids   = [org_id]
    const group_names = [org_name]
    const is_aistemos = (org_name.indexOf('RTIS - LNIP') !== -1)

    return {
      email,
      name: user_name,
      user_id: sub,
      group,
      group_ids,
      group_names,
      [IS_AISTEMOS]: is_aistemos,
    }
  }

  // Otherwise get Cipher user object
  if (!token || !token.realm_access || !token.realm_access.roles) {
    return {}
  }
  const roles = token.realm_access.roles

  const { email, group, group_ids, group_names, name, sub } = token

  return {
    // add properties from roles
    ...roles_to_user_properties(roles),
    // add properties not expressed as roles:
    group,
    group_ids,
    group_names,
    email,
    name,
    user_id: sub,
    [IS_AISTEMOS]: (has_aistemos_group_membership(token) || has_lnip_group_membership(token)), // NOTE: this needs to kept in sync with the server (see auth_utils.js on the server)
    [IS_AISTEMOS_DEMO]: (has_aistemos_demo_group_membership(token)),
    [IS_TECH_INSIGHTS]: has_tech_insights_group_membership(token),
    [IS_LOTNET]: has_lotnet_group_membership(token),
    [IS_GOOGLE]: has_google_group_membership(token),
    [HAS_RESTRICTED_FAMILY_FIELDS]: get_restricted_fields_from_token(token),
    [HAS_REPORT_SERIES_SORT]: get_report_series_sort(token),
    [DEFAULT_CLASSIFIER_ENGINE]: token.default_classifier_engine,
    [PATENTSIGHT_ATTRIBUTES]: token.patentsight,
    roles, // raw roles
    [IDENTITY_PROVIDER]:          token.identity_provider,
    [IDENTITY_PROVIDER_IDENTITY]: token.identity_provider_identity,
    [IS_CIPHER_WAM_USER]:                (token.identity_provider === WAM_PROD_IDP_ID)
  }
}

function roles_to_user_properties(list_of_roles) {
  // transform list of roles from keycloak to user properties object
  // eg ['has_cipher_automotive', 'is_trial'] -> { has_cipher_automotive: true, is_trial: true }
  return _.object(list_of_roles, list_of_roles.map(() => true))
}

function has_aistemos_group_membership(token) {
  return _.contains(token.group, AISTEMOS_GROUP)
}

function has_aistemos_demo_group_membership(token) {
  return _.contains(token.group, AISTEMOS_DEMO_GROUP)
}

function has_lnip_group_membership(token) {
  return _.contains(token.group, LNIP_GROUP) || _.contains(token.group, LNIP_DEMO_1_GROUP)
}

function has_tech_insights_group_membership(token) {
  return _.contains(token.group, TECH_INSIGHTS_GROUP)
}

function has_lotnet_group_membership(token) {
  return _.contains(token.group, LOTNET_GROUP)
}

function has_google_group_membership(token) {
  return _.contains(token.group, GOOGLE_GROUP)
}

function get_restricted_fields_from_token(token) {
  //return ["patFamId", "patentNumbers", "title", "inventors", "expiryDate", "accession", "abstractText", "cpcCodes", CIPHER_FAMILY_ID, ABSTRACT]
  if (!token[RESTRICTED_LIST_VIEW_COLUMNS]) {
    return null
  }
  const restricted_fields = token[RESTRICTED_LIST_VIEW_COLUMNS].split(',')
  // a couple of field names have changed slightly in cipher3
  if (_.contains(restricted_fields, PAT_FAM_ID)) {
    restricted_fields.push(CIPHER_FAMILY_ID)
  }
  if (_.contains(restricted_fields, ABSTRACT_TEXT)) {
    restricted_fields.push(ABSTRACT)
  }
  return restricted_fields
}

function get_report_series_sort(token) {
  if (!token[REPORT_SERIES_SORT]) {
    return null
  }
  return token[REPORT_SERIES_SORT]
}

export function has_cipher_product(user, product) {
  const product_permission = get_product_permission_name(product)
  return user && user[product_permission]
}

export function has_any_cipher_product(user) {
  const user_permissions_list = _.keys(user || {}).filter(p => user[p])
  return _.some(user_permissions_list, permission => is_cipher_product_permission(permission))
}

export function has_orgsets_permissions(user, orgsets_setting) {
  const orgsets_permission = get_orgsets_role(orgsets_setting)
  return user && user[orgsets_permission]
}

export function has_classifiers_edit(user) {
  return user && user[HAS_CLASSIFIERS_EDIT]
}

export function has_text_to_nearest(user) {
  // has_text_to_nearest
  return user && user[HAS_TEXT_TO_NEAREST]
}

export function has_utt(user) {
  return user && true
}

export function has_report_series_sort(user) {
  return user && user[HAS_REPORT_SERIES_SORT]
}

export function has_restricted_family_fields(user) {
  return user && user[HAS_RESTRICTED_FAMILY_FIELDS]
}

export function get_restricted_family_fields(user) {
  return user[HAS_RESTRICTED_FAMILY_FIELDS]
}

export function has_family_details_links(user) {
  if (!has_restricted_family_fields(user)) {
    return true
  }
  const restricted_fields = get_restricted_family_fields(user) || []
  return !_.contains(restricted_fields, PAT_FAM_ID)
}

export function has_trendspotter(user) {
  return user && user[HAS_TRENDSPOTTER]
}

export function has_set_theory_service(user) {
  return user && user[HAS_SET_THEORY_SERVICE]
}

export function has_benchmarking_builder(user) {
  return user && user[HAS_BENCHMARKING_BUILDER]
}

export function is_sso_user(user) {
  return user && user[IS_SSO_USER]
}

export function is_lotnet_member(user) {
  return user && user[IS_LOTNET_MEMBER]
}

export function is_account_disabled(user) {
  return user && user[IS_ACCOUNT_DISABLED]
}

function belongs_to_exactly_one_keycloak_group(user) {
  const {group} = user
  return group && group.length && group.length === 1
}

export function user_has_account_type_role(user) {
  const {roles} = user
  return roles && roles.length && _.some(roles, (role) => _.contains(ACCOUNT_TYPE_ROLES, role))
}

export function has_patentsight_sync_classifiers(user) {
  return user && user[HAS_PATENTSIGHT_SYNC_CLASSIFIERS]
}

export function has_access_to_cipher(user) {
  const is_tech_discovery_context = is_tech_discovery()
  if (is_tech_discovery_context) {
    return true // authorisation is handled separately for TechDiscovery (see AppContainer.js)
  }

  return (
    !is_account_disabled(user)
    && belongs_to_exactly_one_keycloak_group(user)
  )
}

/**
 * Aistemos admin accounts (having the is_admin role on keycloak):
 * - can create new orgs and users via the report sharing ui
 * - can share to users in other organisations
 * - can rerun failed reports belonging to others
 * - can rebuild from params reports belonging to others (caveat: this won't work for reports with other users' private classifiers)
 * - can click links to failed reports in History (to re-trigger/ check report building errors)
 */
export function is_admin(user) {
  return user && is_aistemos(user) && user[IS_ADMIN]
}

/**
 * Aistemos engineering team only. Access to monitoring and debugging tools and customer data
 */
export function is_cipher_engineering(user) {
   return user && is_aistemos(user) && user[IS_CIPHER_ENGINEERING]
}

/**
 * Access to /admin page (bulk cipher user creation and user permissions data). Aistemos users only.
 */
export function has_view_admin_page(user) {
  return user && is_aistemos(user) && user[HAS_VIEW_ADMIN_PAGE]
}

/**
 * Access to /create_users page (bulk cipher user creation and user permissions data). Aistemos/LNIP users only.
 */
export function has_view_create_users_page(user) {
  return user && is_aistemos(user) && user[HAS_VIEW_CREATE_USERS_PAGE]
}

/**
 * Access to assignee grouping tool (Aistemos users only).
 */
export function has_assignee_grouping(user) {
   return user && is_aistemos(user) && user[HAS_ASSIGNEE_GROUPING]
}

/**
 * All accounts in the Aistemos group:
 * - appropriate for demos *unless also having the is_admin role*: should behave virtually the same as client accounts, except for the following:
 * - reports created can be viewed by any user
 * - can view reports created by any user
 * - can expand error messages in the UI for debugging when something goes wrong
 */
export function is_aistemos(user) {
  return user && user[IS_AISTEMOS]
}

export function is_aistemos_demo(user) {
  return user && user[IS_AISTEMOS_DEMO]
}

export function is_classifiers_admin(user) {
  return user && user[IS_CLASSIFIERS_ADMIN]
}

export function is_tech_insights(user) {
  return user && user[IS_TECH_INSIGHTS]
}

export function is_lotnet(user) {
  return user && user[IS_LOTNET]
}

export function has_lotnet_export(user) {
  return user && user[HAS_LOTNET_EXPORT]
}

export function has_reassignment_export(user) {
  return user && user[HAS_REASSIGNMENT_EXPORT]
}

export function is_google(user) {
  return user && user[IS_GOOGLE]
}

export function is_creator(user) {
  return user && !user[IS_LITE] && !user[IS_VIEW_ONLY]
}

export function is_view_only_user(user) {
  return user && user[IS_VIEW_ONLY]
}

export function is_poweruser(user) {
  return user && user[IS_POWERUSER]
}

export function is_trial(user) {
  return user && user[IS_TRIAL]
}

function get_product_permission_name(product) {
  // NB: it's assumed that permissions for any new products we add will have the format 'has_cipher_{product_id}'
  return product === PRODUCT_ND ? HAS_ND_REPORT : `has_cipher_${product}`
}

function get_orgsets_role(orgsets_setting) {
  // NB: it's assumed that permissions for industry specific org sets is of the form 'has_{industry_sector}_orgsets
  return  `has_${orgsets_setting}`
}

const CIPHER_PRODUCT_REGEX = /^has_cipher_[a-z0-9._]*$/

export function get_permitted_product_ids(user) {
  // NOTE: this does not include N/D
  return Object.keys(user)
    .filter((key) => key.match(CIPHER_PRODUCT_REGEX))
    .map((key) => key.replace('has_cipher_', ''))
}

export function has_nd_related_permissions(user) {
  return user && user[HAS_ND_REPORT]
}

function is_cipher_product_permission(permission_name) {
  // NB: it's assumed that permissions with the name format 'has_cipher_{someword}' refer to cipher products
  return permission_name === HAS_ND_REPORT || permission_name.match(CIPHER_PRODUCT_REGEX)
}

export function can_view_tagged_families(user) {
  return user && user[HAS_VIEW_TAGGED_FAMILIES]
}

export function can_tag_families(user) {
  return user && user[CAN_TAG_FAMILIES]
}

export function has_hs_google_valuation(user) {
  return user && user[HAS_HS_GOOGLE_VALUATION]
}

export function is_user_not_authenticated(user) {
  return _.isEmpty(user)
}

export function has_pvix_enabled(user) {
  return user && !user[HAS_PVIX_DISABLED]
}

export function has_quick_org_search(user) {
  return user && user[HAS_QUICK_ORG_SEARCH]
}

export function has_us_centric_territories(user) {
  return user && user[HAS_US_CENTRIC_TERRITORIES]
}

export function can_select_nd_version(user) {
  return is_google(user) || is_cipher_engineering(user)
}

export function has_old_weekly_classifier_alerts(user) {
  return user && user[HAS_OLD_CLASSIFIER_ALERTS]
}

export function has_tracking_disabled(user) {
  return user && user[HAS_TRACKING_DISABLED]
}
