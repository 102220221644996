import React from 'react'
import { withRouter } from 'react-router-dom'

import { DashboardSmallTile } from './DashboardTile.js'
import { DashboardTileDesc } from './DashboardTileDesc.js'
import { track_visit_event } from '../../utils/tracking_utils.js'
import { DashboardTileInner } from './DashboardTileInner.js'
import { TEXT_TO_NEAREST } from '../../constants/paths.js'

const TextToNearestPanel = ({history}) => {
  function on_click_from_new_report_btn() {
    track_visit_event('action="select_text_to_nearest" context="dashboard"')
    history.push(TEXT_TO_NEAREST)
  }

  return (
    <DashboardSmallTile
      title='Classify documents'
      //hint={<TileHelpLink help_url={get_custom_clustered_report_help_url()}  />}
      on_click={on_click_from_new_report_btn}
    >
      <DashboardTileInner>
        <DashboardTileDesc>
          Find nearest classes to draft patent documents
        </DashboardTileDesc>
      </DashboardTileInner>
    </DashboardSmallTile>
  )
}

export default withRouter(TextToNearestPanel)