import React, { useEffect, useState } from 'react'
import { DropdownItem } from 'reactstrap'
import { LinearProgress } from '@mui/material'
import cn from 'classnames'
import axios from 'axios'
import moment from 'moment'

import { has_text_to_nearest } from '../../../utils/user_permissions.js'
import ContainerFullWidth from '../../ContainerFullWidth.js'
import { withUser } from '../../UserContext.js'
import { PrimaryButton } from '../../widgets/Button.js'
import ErrorBody from '../../ErrorBody.js'
import { get_clean_filename } from '../../../utils/download_utils.js'
import { get_presigned_url, submit_job } from '../utils/text_to_nearest_utils.js'
import { sum } from '../../../utils/utils.js'
import { get_classifier_groups, IS_USER_TAXONOMY, USER_OTHER_ID } from '../../../utils/classifier_group_utils.js'
import { get_leaf_nodes_as_array } from '../../../utils/classifier_tree_utils.js'
import Spinner from '../../widgets/Spinner.js'
import BaseDropdown from '../../widgets/BaseDropdown.js'
import Label from '../../widgets/Label.js'
import ScrollableList from '../../widgets/ScrollableList.js'
import { RadiobuttonWithLabel } from '../../widgets/RadiobuttonWithLabel.js'

import cs from '../../cipher_styles.module.scss'
import s from './TextToNearest.module.scss'

const MAX_NUM_FILES = 1000

const MAX_TOTAL_SIZE       = 5 * 1024 * 1024 * 1024
const MAX_TOTAL_SIZE_HUMAN = '5GB'

const ESTIMATED_SECONDS_PER_DOCUMENT = 20

function get_progress(files_loaded, files_totals) {
  const loaded = sum(files_loaded)
  const total = sum(files_totals)
  if ((total === 0)) {
    return 0
  }
  return loaded / total
}

function get_available_taxonomies(classifier_groups) {
  const available_classifier_groups = classifier_groups.reduce((acc, group) => {
    // Omit user group "other"
    if (group.id === USER_OTHER_ID) {
      return acc
    }

    // Omit old-style taxonomy "products" (only use user taxonomy paths - this ensures user can access classifiers via TrainingSetService API)
    // If we want to allow old-style taxonomy "products", TrainingSetService API needs some special permission to access (i.e. maybe via an admin call)
    if (!group[IS_USER_TAXONOMY]) {
      return acc
    }

    // Omit groups with less than 2 classifiers
    const classifiers = get_leaf_nodes_as_array(group)
    if (classifiers.length < 2) {
      return acc
    }

    // Add this group
    const group_with_leaf_classifiers = {...group, classifiers}
    return [...acc, group_with_leaf_classifiers]
  }, [])

  return available_classifier_groups
}

function validate_files(files) {
  if (files == null) {
    return [false, null]
  }
  if (files.length === 0) {
    return [false, 'No files selected']
  }
  if (files.length > MAX_NUM_FILES) {
    return [false, `Too many files selected, please choose less than ${MAX_NUM_FILES} files`]
  }
  const total_size = sum(files.map(file => file.size))
  if (total_size > MAX_TOTAL_SIZE) { // max 5GB total
    return[false, `Total size of files exceeds ${MAX_TOTAL_SIZE_HUMAN}, please choose less than ${MAX_TOTAL_SIZE_HUMAN} of files.`]
  }
  return [true, null]
}

function get_full_group_name(name, classifiers) {
  return `${name} (${classifiers.length} classifiers)`
}

function get_estimated_processing_time(num_docs, num_classifiers) {
  // Returns value in seconds.
  // The vast majority of the time is spent on LLM text extraction (per document).
  // Everything else is fast.
  // i.e. pulling in the classifier positive vectors is very fast, even with hundreds of classifiers, each with hundreds of positives.
  // i.e. the matrix distance calculation should also be pretty fast, even with 1k documents x 10k positives (i.e. 10 million distances).
  return num_docs * ESTIMATED_SECONDS_PER_DOCUMENT
}

function format_processing_time(num_seconds) {
  return moment.duration(num_seconds, 'seconds').humanize()
}

const TextToNearest = ({ user }) => {
  const is_permitted = has_text_to_nearest(user)

  const [is_initial_fetch, set_is_initial_fetch] = useState(false)
  const [error_initial_fetch, set_error_initial_fetch] = useState(null)

  const [is_processing, set_is_processing] = useState(false)
  const [error_processing, set_error_processing] = useState(null)

  const [selected_files, set_selected_files] = useState()

  const [files_loaded, set_files_loaded] = useState(null)
  const [files_totals, set_files_totals] = useState(null)

  const [available_classifier_groups, set_available_classifier_groups]     = useState(null)
  const [selected_classifier_group_idx, set_selected_classifier_group_idx] = useState(null)

  const [is_job_submitted, set_is_job_submitted] = useState(null)
  const [num_docs_submitted, set_num_docs_submitted] = useState(null)
  const [num_classifiers_submitted, set_num_classifiers_submitted] = useState(null)

  const { email, group_names, group_ids, user_id} = user
  const group_name = group_names[0]
  const group_id = group_ids[0]

  useEffect(() => {
    // On page load
    get_classifier_groups(user, false)
    .catch(err => {
      set_error_initial_fetch(err)
      throw err
    })
    .then(classifier_groups => {
      const available_classifier_groups = get_available_taxonomies(classifier_groups)
      set_available_classifier_groups(available_classifier_groups)
      if (available_classifier_groups.length > 0) {
        set_selected_classifier_group_idx(0)
      }
    })
    .finally(() => {
      set_is_initial_fetch(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!is_permitted) {
    return (
      <div className={''}>
        <ContainerFullWidth className='mt-3'>
          You do not have access to Text-to-nearest.
        </ContainerFullWidth>
      </div>
    )
  }

  const [is_files_valid, invalid_files_message] = validate_files(selected_files)

  const progress = (files_loaded == null || files_totals == null) ? null : get_progress(files_loaded, files_totals)

  const selected_classifier_group = (selected_classifier_group_idx != null) ? available_classifier_groups[selected_classifier_group_idx] : null
  const selected_classifier_group_name = (selected_classifier_group_idx != null) ? get_full_group_name(selected_classifier_group.name, selected_classifier_group.classifiers) : null

  const estimated_job_time = is_job_submitted ? get_estimated_processing_time(num_docs_submitted, num_classifiers_submitted) : null
  const estimated_job_time__human = estimated_job_time ? format_processing_time(estimated_job_time) : null

  function do_process() {
    set_is_processing(true)
    set_error_processing(null)
    set_is_job_submitted(false)
    set_num_classifiers_submitted(null)
    set_num_docs_submitted(null)

    const files_totals = selected_files.map(file => file.size)
    const files_loaded = selected_files.map(file => 0)
    set_files_totals(files_totals)
    set_files_loaded(files_loaded)

    const folder_name = `${group_name}-${Date.now()}`
    const folder_name__clean = get_clean_filename(folder_name)

    // Get pre-signed S3 URLs
    Promise.all(
      selected_files.map(file => get_presigned_url(folder_name__clean, file.name))
    )
    .then(responses => {
      const presigned_urls = responses.map(response => response.presigned_url)
      const s3_folder_urls = responses.map(response => response.s3_folder_url)

      // Upload files to S3
      return Promise.all(
        selected_files.map((file, i) => {
          return axios.put(presigned_urls[i], file, {
            headers: {
              "Content-Type": file.type
            },
            onUploadProgress: (e) => {
              set_files_loaded(files_loaded_prev => {
                return [
                  ...files_loaded_prev.slice(0, i),
                  e.loaded,
                  ...files_loaded_prev.slice(i+1),
                ]
              })
            },
          })
        })
      )
      .then(() => {
        // Submit Job to AWS Batch
        const s3_input_folder = s3_folder_urls[0]
        const input_classifier_ids = selected_classifier_group.classifiers.map(classifier => classifier.classifier_id)
        return submit_job(email, user_id, group_id, s3_input_folder, input_classifier_ids)
      })
    })
    .then(responses => {
      // SUCCESS
      set_is_job_submitted(true)
      set_num_docs_submitted(selected_files.length)
      set_num_classifiers_submitted(selected_classifier_group.classifiers.length)
    })
    .catch(err => {
      // FAIL
      set_error_processing(err)
    })
    .finally(() => {
      set_is_processing(false)
    })
  }

  function reset() {
    set_selected_files(null)
    set_files_loaded(null)
    set_files_totals(null)
    set_is_job_submitted(false)
    set_num_docs_submitted(null)
    set_num_classifiers_submitted(null)
  }

  return (
    <ContainerFullWidth className={cn('mt-3')}>

      <h2 className={cn('mb-3')}>Find nearest classes</h2>

      {is_initial_fetch &&
        <div>
          <Spinner size='sm' />
        </div>
      }

      {error_initial_fetch &&
        <div>
          <ErrorBody
            error={error_initial_fetch}
            context={'fetching taxonomies for text-to-nearest'}
          />
        </div>
      }

      {(available_classifier_groups != null && available_classifier_groups.length === 0) &&
        <div>
          No taxonomies available.
        </div>
      }

      {(!is_job_submitted && available_classifier_groups != null && available_classifier_groups.length > 0) &&
        <div>

          <div className={cn('d-flex', 'align-items-center')}>
            <Label className={cn(cs.font_weight_normal, cs.white_space_nowrap, s.label)}>
              Taxonomy
            </Label>

            <BaseDropdown
              className={''}
              label={selected_classifier_group_name}
              right={false}
            >
              <ScrollableList>
                {available_classifier_groups.map((classifier_group, idx) => {
                  const { name, classifiers } = classifier_group
                  const full_group_name = get_full_group_name(name, classifiers)
                  const is_selected = (idx === selected_classifier_group_idx)

                  return (
                    <DropdownItem
                      className={cn('d-flex', 'align-items-center', s.row_container)}
                      key={idx}
                      //toggle={false} // on click, keep the dropdown open
                      tag={'div'}
                    >
                      <RadiobuttonWithLabel
                        is_checked={is_selected}
                        on_click={set_selected_classifier_group_idx.bind(null, idx)}
                        label={full_group_name}
                      />
                    </DropdownItem>
                  )
                })}
              </ScrollableList>
            </BaseDropdown>

          </div>

          <div className={cn('mt-3', 'd-flex', 'align-items-center')}>
            <Label className={cn(cs.font_weight_normal, cs.white_space_nowrap, s.label)}>
              Documents
            </Label>

            <input
              type="file"
              accept=".docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              multiple
              onChange={(e) => set_selected_files(Array.from(e.target.files))}
            />
          </div>


          {invalid_files_message &&
            <div className={cn('mt-1', 'text-danger')}>{invalid_files_message}</div>
          }

          <div className={cn('mt-4', 'd-flex', 'align-items-center')}>
            <PrimaryButton
              onClick={do_process}
              disabled={!is_files_valid || selected_classifier_group_idx==null || is_processing || error_processing || is_job_submitted}
            >
              Proceed
            </PrimaryButton>

            {(is_processing) && (progress != null) &&
              <div className={cn('ms-3', 'flex-grow-1', 'd-flex', 'align-items-center')}>
                <span>Uploading files: </span>
                <LinearProgress
                  variant="determinate"
                  value={Math.floor(progress * 100)}
                  className={cn('ms-3', 'flex-grow-1')}
                />
                <span className={cn('ms-3', s.progress_pc_label, 'text-end')}>{Math.floor(progress * 100)}%</span>
              </div>
            }
          </div>

          <div className={cn('mt-4')}>
            {error_processing &&
              <ErrorBody
                error={error_processing}
                context={'processing text-to-nearest job'}
              />
            }
          </div>

        </div>
      }

      {is_job_submitted &&
        <div>
          <p>Successfully uploaded your documents.</p>
          <p>We are now processing your job ({num_docs_submitted} documents, across {num_classifiers_submitted} classifiers).</p>
          <p>Estimated processing time is {estimated_job_time__human}.</p>
          <p>You will receive an email with the results.</p>
          <p>Meanwhile you can close this page if you wish.</p>
          <PrimaryButton
            className={cn('mt-4')}
            onClick={reset}
          >
            Create another job
          </PrimaryButton>
        </div>
      }

    </ContainerFullWidth>
  )
}

export default withUser(TextToNearest)